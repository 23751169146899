
import { defineComponent } from "vue";
import {
  Col,
  Row,
  Input,
  Button,
  Select,
  Table,
  Pagination,
  Popover,
  message,
  Switch,
} from "ant-design-vue";
import { UnorderedListOutlined } from "@ant-design/icons-vue";
import { listArea, disableAreaByCode } from "../../../api/terminalList.js";
import { areaColumns } from "@/api/fixedData.js";
const { Option } = Select;
export default defineComponent({
  components: {
    Col,
    Row,
    Input,
    Button,
    Select,
    UnorderedListOutlined,
    Table,
    Pagination,
    Popover,
    Option,
    Switch,
  },
  data() {
    return {
      categoryOptions: [],
      brandOptions: [],
      //数据相关
      province: "",
      city: "",
      area: "",
      town: "",
      code: "",
      //table的数据
      data: [],
      //table的列名字
      columns: [],
      //复选框的选择结果
      selectedRowKeys: [],
      // 当前页数
      pageCurrent: 1,
      // 每页数据数量
      pageSize: 10,
      pageNumber: 1,
      //总数据量
      total: 0,
      tableHeight: "400px",
      searchFlag: false,
      //拓展字段
      customData: [],
      keepCustomData: [],
      //table的布局
      scroll: { x: "100%" },
      //table的loading
      loading: true,
      //批量操作按钮加载
      batchLoading: false,
      //默认状态
      status: "",
    };
  },
  methods: {
    //复选结果
    onSelectChange(selectedRowKeys: any) {
      //
      this.selectedRowKeys = selectedRowKeys;
    },
    //更换每页显示数量
    onShowSizeChange(current: any, pageSize: any) {
      this.pageNumber = 1;
      this.pageCurrent = 1;
      this.pageSize = pageSize;
      this.getTerminal();
    },
    //弹出Popover 进行筛选
    handelColumns() {
      (this as any).columns = [...areaColumns, ...this.keepCustomData];
      (this as any).columns.push({
        title: "状态",
        slots: { customRender: "handle" },
        align: "left",
        fixed: "right",
      });
    },
    inputChange(item) {
      if (item.checked) {
        (this as any).keepCustomData.splice(
          item.key,
          0,
          this.customData[item.key]
        );
      } else {
        for (let i = 0; i < (this as any).keepCustomData.length; i++) {
          if (!(this as any).keepCustomData[i].checked) {
            (this as any).keepCustomData.splice(i, 1);
          }
        }
      }

      //

      this.handelColumns();
    },
    //跳转到终端详情页面
    terminalDetails(code) {
      if (code === undefined) {
        code = "new";
      }
      (this as any).$router.push(`/masterData/area/details/${code}`);
    },
    //计算终端状态
    computedStatus(status) {
      switch (status) {
        case false:
          return "禁用";
        case true:
          return "启用";
        default:
          return "系统错误";
      }
    },
    //禁用终端 单个
    disableAreaByCode(code, status) {
      status = status == "0" ? "1" : "0";
      let params = {
        codes: [code],
        status: status,
      };
      disableAreaByCode(params).then((res) => {
        if (res.data.success) {
        }
        this.getTerminal();
      });
    },
    //批量禁用
    disableBatch(code) {
      let params = {
        codes: [...code],
        status: 0,
      };
      disableAreaByCode(params).then((res) => {
        //
        if (res.data.success) {
          message.success("批量禁用成功");
        }
        // else {
        //   message.error(res.data.data);
        // }
        this.getTerminal();
      });
    },
    //获取单品列表
    getTerminal() {
      this.loading = true;
      listArea({
        province: this.province,
        city: this.city,
        area: this.area,
        town: this.town,
        code: this.code,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        status: this.status,
      }).then((res: any) => {
        if (res.data.success) {
          this.total = res.data.total;
          this.data = res.data.data;
          this.loading = false;
        }
        // else{
        //   message.error(res.data.errorMessage)
        // }
      });
    },
    //重置
    reset() {
      this.province = "";
      this.city = "";
      this.area = "";
      this.town = "";
      this.code = "";
      this.status = "";
      this.search();
      this.searchFlag = false;
    },
    //搜索
    search() {
      this.searchFlag = true;
      this.pageNumber = 1;
      this.getTerminal();
      this.pageCurrent = 1;
    },
    //跳转到导入页面
    batchImport() {
      this.$router.push("/masterData/singleProduct/batchImport");
    },
    //批量导出
    // batchExport() {
    //   this.batchLoading = true;
    //   exportSingleProductList({
    //     province: this.province,
    //     city: this.city,
    //     area: this.area,
    //     town: this.town,
    //     code: this.code,
    //   }).then((res) => {
    //
    //     if (res.data.success) {
    //       window.open(res.data.data);
    //     }
    //     // else {
    //     //   message.error(res.data.errMessage);
    //     // }
    //     this.batchLoading = false;
    //   });
    // },
    //预览图片
    previewPicture(url) {
      (this as any).$refs.showPreModal.showModal(url);
    },
    //计算当前是否禁用状态
    computeStatus(status) {
      if (status == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    //监听页码和页面数量变化
    pageCurrent() {
      //
      if (this.searchFlag === false) {
        this.province = "";
        this.city = "";
        this.area = "";
        this.town = "";
        this.code = "";
        this.status = "";
      }
      this.selectedRowKeys = [];
      this.pageNumber = this.pageCurrent;
      this.getTerminal();
    },
  },
  mounted() {
    // this.tableHeight = window.screen.height - 564 + "px";
    // this.scroll.y = window.screen.height - 600;
    (this as any).$store.commit("changeBreadcrumb", [
      "主数据中心",
      "地区管理",
      "地区列表",
    ]);
    //获取列表信息
    this.getTerminal();
    (this as any).columns = areaColumns;
  },
});
